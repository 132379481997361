import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'music',
    loadChildren: () => import('./modules/music/music.module').then(m => m.MusicModule),
  },
  {
    path: "**",
    redirectTo: 'music',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
